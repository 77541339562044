import { type FC } from 'react';
import { Link } from 'react-router-dom';
import { Button, Table } from 'flowbite-react';
import { type Analytic } from 'types/analytic';

type Props = {
  analytic: Analytic;
};

const AnalyticTableRow: FC<Props> = ({ analytic }) => {
  return (
    <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
      <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white max-w-40 truncate">
        {analytic.id}
      </Table.Cell>
      <Table.Cell>{analytic.name}</Table.Cell>
      <Table.Cell>{analytic.websiteUrl}</Table.Cell>
      <Table.Cell>{analytic.status}</Table.Cell>
      <Table.Cell>{analytic.role}</Table.Cell>
      <Table.Cell>
        <Button color="gray" as={Link} to={`/analytics/${analytic.id}`}>
          <span className={'text-md'}>詳細</span>
        </Button>
      </Table.Cell>
    </Table.Row>
  );
};

export default AnalyticTableRow;
